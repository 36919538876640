import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export const pageContentSlice = createSlice({
  name: 'pageContent',
  initialState: {
    blogNewsEventsContainer: [[],[],[],[]],
    allType1Count: 0,
    allType2Count: 0,
    allType3Count: 0,
    type1Count: 0,
    type2Count: 0,
    type3Count: 0,
    type1Max: 7,  // default is one more than 6 so that the load more button is visible
    type2Max: 7,
    type3Max: 7,
    showCookieBanner: false,
    isTrad: true,
    shouldUpdate: true,
    shouldPersonalize: true,
    navigationTheme: 'Dark',
  },
  reducers: {
    addValues: (state:any, action:any) => {
        return { ...state, ...action.payload};
    },
    toggleCookieBanner: (state:any, action: PayloadAction<boolean>) => {
      const payload: boolean  = action.payload;
      const showCookieBanner = payload;
      return {...state, showCookieBanner};  
    },
    togglePersonalize: (state:any, action: PayloadAction<boolean>) => {
      const payload: boolean  = action.payload;
      const shouldPersonalize = payload;
      return {...state, shouldPersonalize};  
    },
    updateNavigationTheme: (state:any, action: PayloadAction<string>) => {
      const payload: string  = action.payload;
      const navigationTheme = payload;
      return {...state, navigationTheme};  
    },
  }
});
export const { addValues, toggleCookieBanner, togglePersonalize, updateNavigationTheme } = pageContentSlice.actions

export default pageContentSlice.reducer