import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "../../../_assets/x-close-blue.svg"
import styles from "./ModalDialog.module.scss";
import ModalBody from "./ModalBody";
import { connect, useDispatch } from "react-redux";
import { closeModal } from "../../../../redux/slices/modalSlice";
import { RootState } from "../../../../redux/store";
import { useEffect } from "react";

interface IModalDialogProps{
    modalType?: string,
    isModalOpen?: boolean,
    className?: string,
    windowWidth?: number,
    dialogTitle?: string,
    body?: any,
    header?: any,
    subHeader?: any,
    image?: any,
    altText?: string,
    link?: any
}

function mapStateToProps(state: RootState): any {
  const modal: IModalDialogProps = state.modal || {};
  const { isModalOpen, modalType, dialogTitle, body, image, altText, header, subHeader, link } = modal;
  const { windowWidth } = state.global;
  return { isModalOpen, modalType, windowWidth, dialogTitle, body, image, altText, header, subHeader, link };
}
function setDialogTitle(modalType: string | undefined){
  switch (modalType){
    case "Backdrop":
        return "";
    case "Cookies":
        return "Cookie Preferences"
    default:
        return "Apply for a Loan";
  }
}
function modalClass(modalType: string | undefined){
  switch (modalType){
    case "Cookies":
        return styles["cookie-preferences"]
    default:
        return undefined;
  }
}
// Backdrop is essentially an overlay component.  The height of the modal is 0, and has pass-through pointer events
function paperClass(modalType: string | undefined){
  switch (modalType){
    case "Bio":
      return "bio-modal";
    case "Backdrop":
      return "backdrop-modal";
    case "Apply":
      return "apply-modal";
    case "Cookies":
        return "cookies-modal"
    default:
        return "";
  }
}
function rootClass(modalType: string | undefined){
  switch (modalType){
    case "Backdrop":
      return "pass-through";
    default:
        return "";
  }
}
function backdropStyle(modalType: string | undefined){
  const cookieBannerHeight = document.getElementById("cookie-banner")?.clientHeight || 214;
  switch (modalType){
    case "Backdrop":
        return { backdrop: { style: { bottom: cookieBannerHeight}}};
    default:
        return undefined;
  }
}
function shouldUseCloseImage(modalType: string | undefined){
  switch (modalType){
    case "Bio":
        return false;
    default:
        return true;
  }
}

function DynamicModal({ modalType, isModalOpen, className, windowWidth, dialogTitle, body, image, altText, header, subHeader, link}: IModalDialogProps) {

    const dispatch = useDispatch();

    useEffect(()=>{
      // Backdrop modal needs to adjust cookieBannerHeight when windowWidth changes, this code causes a re-render
    },[windowWidth])

    return (
    <Dialog
      className={`${styles.dialog} ${className} modalDialog ${modalClass(modalType)}`}
      classes={{
        paper: `mf-modal dynamic-modal ${paperClass(modalType)}`,
        root: `${rootClass(modalType)}`,
      }}
      slotProps={backdropStyle(modalType)}
      aria-labelledby="apply-dialog-title"
      open={isModalOpen === undefined ? false : isModalOpen}
      fullWidth={true}
      maxWidth = {'md'}
    >
        <DialogTitle className={`dialog-title modal-title ${styles["modal-title"]}`} classes={{root: "ps-0"}}>
          {dialogTitle || setDialogTitle(modalType)}
        </DialogTitle>
        <DialogContent dividers classes={{ dividers: 'border-0 ps-0' }}>
            <ModalBody 
              modalType={modalType} 
              body={body} 
              image={image} 
              altText={altText}
              header={header}
              subHeader={subHeader}
              link={link}
              />
        </DialogContent >
        <IconButton 
          aria-label="close" 
          onClick={(e)=>{
            e.preventDefault();
            dispatch(closeModal(null));
          }}
          classes={{root: `closeButton p-0 ${styles["mfp-close"]}`}} 
          className={styles.closeButton}
        >
            {shouldUseCloseImage(modalType) && <img src={CloseIcon} alt="close" width={44} height={44} />}    
        </IconButton>
    </Dialog>);
}
export default connect(mapStateToProps, undefined)(DynamicModal);
