import IComponentProps from "./interfaces/IComponentProps";
import ContentTypesEnum from "./enums/ContentTypesEnum";
import PromotionModel from "./models/PromotionModel";
import PromoAccordionsModel from "./models/PromoAccordionsModel";
import Promotional from "./App/components/Promotional/Promotional";
import CategoryCardList from "./App/components/CategoryCardList/CategoryCardList";
import CategoryCardListModel from "./models/CategoryCardListModel";
import CTABarProductDetail from "./App/components/CTABarProductDetail/CTABarProductDetail";
import CTABarProductModel from "./models/CTABarProductModel";
import ProductCarousel from "./App/components/ProductCarousel/ProductCarousel";
import ProductCarouselModel from "./models/ProductCarouselModel";
import TestimonialCarousel from "./App/components/TestimonialCarousel/TestimonialCarousel";
import TestimonialCarouselModel from "./models/TestimonialCarouselModel";
import Feature from "./App/components/Feature/Feature";
import FeatureModel from "./models/FeatureModel";
import ProductStepwiseDetails from "./App/components/ProductStepwiseDetails/ProductStepwiseDetails";
import ProductStepwiseDetailModel from "./models/ProductStepwiseDetailModel";
import FAQ from "./App/components/FAQ/FAQ";
import FAQModel from "./models/FAQModel";
import { CompareTable } from "./App/components/CompareTable/CompareTable";
import CompareTableModel from "./models/CompareTableModel";
import TabsTallModel from "./models/TabsTallModel";
import TallTabs from "./App/components/TallTabs/TallTabs";
import { Disclosure } from "./App/components/Disclosures/Disclosures";
import DisclosureItemModel from "./models/DisclosureItemModel";
import ContentBlockDynamic from "./App/components/ContentBlock/ContentBlockDynamic";
import ContentBlockModel from "./models/ContentBlockModel";
import Anchors from "./App/components/Anchors/Anchors";
import AnchorsModel from "./models/AnchorsModel";
import PromoAccordions from "./App/components/PromoAccordions/PromoAccordions";
import Accordions from "./App/components/Accordions/Accordions";
import AccordionsModel from "./models/AccordionsModel";
import ImageList from "./App/components/ImageList/ImageList";
import ImageListModel from "./models/ImageListModel";
import { Calculator } from "./App/components/Calculator/Calculator";
import CalculatorModel from "./models/CalculatorModel";
import Sitemap from "./App/components/Sitemap/Sitemap";
import SitemapModel from "./models/SitemapModel";
import AnyDynamicContentModel from "./redux/_shared/helpers/AnyDynamicContentModel.type";
import createAssociativeArray from "./redux/_shared/helpers/createAssociativeArray";
import PersonalizedPromoModel from "./models/PersonalizedPromoModel";
import PersonalizedCategoryModel from "./models/PersonalizedCategoryModel";
import { PersonalizedPromo } from "./App/components/Personalization/PersonalizedPromo";
import { PersonalizedCategoryCards } from "./App/components/Personalization/PersonalizedCategoryCards";
import ComponentContentBlockModel from "./models/ComponentContentBlockModel";
import ComponentContentBlock from "./App/components/ContentBlock/ComponentContentBlock";
import FormModel from "./models/FormModel";
import DynamicForm from "./App/components/Form/DynamicForm";

export interface IDynamicTemplateConfigEntry{
    component: (props: IComponentProps) => JSX.Element,
    model: AnyDynamicContentModel & Function
}

const _dynamicComponentConfig = createAssociativeArray<ContentTypesEnum, IDynamicTemplateConfigEntry>()
    .set(ContentTypesEnum.Anchor, {component: Anchors, model: AnchorsModel})
    .set(ContentTypesEnum.Accordions, {component: Accordions, model: AccordionsModel})
    .set(ContentTypesEnum.ProductCarousel, {component: ProductCarousel, model: ProductCarouselModel })
    .set(ContentTypesEnum.TestimonialCarousel, {component: TestimonialCarousel, model: TestimonialCarouselModel})
    .set(ContentTypesEnum.CategoryCardList, {component: CategoryCardList, model: CategoryCardListModel})
    .set(ContentTypesEnum.CTABarProductDetail, {component: CTABarProductDetail , model:CTABarProductModel})
    // @ts-ignore
    .set(ContentTypesEnum.DynamicForm, {component: DynamicForm, model: FormModel})
        // @ts-ignore
    .set(ContentTypesEnum.FAQ, {component: FAQ, model: FAQModel})
    .set(ContentTypesEnum.Feature, {component: Feature, model: FeatureModel})
    .set(ContentTypesEnum.ImageList, {component: ImageList , model: ImageListModel})
    .set(ContentTypesEnum.Promotional, {component: Promotional, model:PromotionModel})
    .set(ContentTypesEnum.ProductStepwiseDetail, {component: ProductStepwiseDetails , model: ProductStepwiseDetailModel})
    // @ts-ignore
    .set(ContentTypesEnum.CompareTable, {component: CompareTable , model: CompareTableModel})
    // @ts-ignore
    .set(ContentTypesEnum.TabsTall, {component: TallTabs, model: TabsTallModel})
    .set(ContentTypesEnum.ContentBlock, {component: ContentBlockDynamic, model: ContentBlockModel})
    // @ts-ignore
    .set(ContentTypesEnum.ComponentContentBlock, {component: ComponentContentBlock, model: ComponentContentBlockModel})
    .set(ContentTypesEnum.PromoAccordions, {component: PromoAccordions, model: PromoAccordionsModel})
    // @ts-ignore
    .set(ContentTypesEnum.Disclosure, {component: Disclosure, model: DisclosureItemModel})
    .set(ContentTypesEnum.Sitemap, {component: Sitemap, model: SitemapModel})
    // @ts-ignore
    .set(ContentTypesEnum.Calculator, {component: Calculator, model: CalculatorModel})
    // @ts-ignore
    .set(ContentTypesEnum.PersonalizedPromo, {component: PersonalizedPromo, model: PersonalizedPromoModel})
    // @ts-ignore
    .set(ContentTypesEnum.PersonalizedCategoryCards, {component: PersonalizedCategoryCards, model: PersonalizedCategoryModel})
export default _dynamicComponentConfig;

