
import IComponentProps from "../../../interfaces/IComponentProps";
import stylesBase from "./Locator.module.scss";
import LocatorModel from "../../../models/LocatorModel";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";

export const REACT_APP_LOCATION_APP_BASE_URL = process.env.REACT_APP_LOCATION_APP_BASE_URL;

if(!REACT_APP_LOCATION_APP_BASE_URL){
    throw new Error("Missing Location-App-Base-Url key in environment configuration");
}

export function Locator({header, id}:  LocatorModel & IComponentProps) {
    return (
        <div className={`layout ${stylesBase.locator}`} data-contentid={id}>
            <div className="container">
                <h1>{header}</h1>
                <div className="row g-0">
                    <div className="col">
                        <iframe
                            src={REACT_APP_LOCATION_APP_BASE_URL}
                            width="100%"
                            height="700"
                            title="Locator Application"
                            allow="geolocation"
                            sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withErrorBoundary(Locator);
