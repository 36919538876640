
import SmartLink from "../../../components/_shared/SmartLink/SmartLink";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import { showSearch } from "../../../environmentVariables";
import { TextField, InputAdornment } from "@mui/material";
import Search from "../../../_assets/magnifying-glass-blue.svg";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import { connect } from "react-redux";
import { mapStateToProps } from "../../SearchPage/SearchPage";
import { mobileMenuToggleAction, setSearch } from "../../../../redux/slices/globalSlice";
import { useNavigate } from "react-router-dom";

interface ISubMenuToolbar {
    links: Array<SmartLinkModel> | undefined;
    routingNumber: string | undefined;
    linkData: any;
    setSearch: Function;
    mobileMenuToggleAction: Function;
    search: string;
}
const mapDispatchToProps = (dispatch: any, _: any):any => {
    return {
        setSearch: (searchString: string)=> dispatch(setSearch(searchString)),
        mobileMenuToggleAction: ()=> dispatch(mobileMenuToggleAction(null))
    };
};

function SubMenuToolbar(props: ISubMenuToolbar) {
    let {linkData, links, setSearch, search, mobileMenuToggleAction} = props;
    const navigate = useNavigate();

    return <>
        {showSearch && <li className="mobile-search">
            <form onSubmit={(e)=>{
                    e.preventDefault();
                    mobileMenuToggleAction();
                    navigate("/search-results");
                }}>
                <TextField
                    variant="outlined" 
                    label={"Search"}  
                    type="text"
                    id="mobile-txtSearch" 
                    name="search"
                    fullWidth
                    onChange={(e)=>{setSearch(e.target.value)}}
                    classes={{
                        root: "field"
                    }} 
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={Search} alt="search" /></InputAdornment>,
                      }}
                    value={search}
                    size={"medium"} />
            </form>
        </li>}
        <li>
            <div className="mobile-toolbar">
                {links?.map((linkWrapper, index) =>{
                    return <SmartLink key={index} {...linkWrapper} onClick={()=>{
                        mobileMenuToggleAction();
                    }}/>;
                })}
            </div>
        </li>
        <li className="cta">
            <span className="mobile-menu-ctas" >{props.routingNumber}</span>
            <div className="mobile-menu-ctas" >
                <SmartLink {...linkData} type={LinkTypeEnum.PrimaryButton}/>
            </div>
        </li>
    </>;
}
export default connect (mapStateToProps, mapDispatchToProps) (withErrorBoundary(SubMenuToolbar));