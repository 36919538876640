import IComponentProps from "../../../interfaces/IComponentProps";
import {useEffect, useState} from "react";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import TabsTallModel from "../../../models/TabsTallModel";
import {TallTab} from "./TallTab";
import "./TallTabs.module.scss";
import TallTabTypeEnum from "../../../enums/TallTabTypeEnum";
import TabDetailPanel from "./TabDetailPanel/TabDetailPanel";
import { connect } from "react-redux";
import PageDisplayTypeEnum from "../../../enums/PageDisplayTypeEnum";
import { KeyCode } from "../../_shared/helpers/keyCode";
import { RootState } from "../../../redux/store";

interface IMapStateProps{
    windowWidth: number
}
    
export const mapStateToProps = (state: RootState): IMapStateProps => {
    const {windowWidth} = state.global;
    return {windowWidth };
};

const keyDownHandler = (e: any, startingFocus: number, numberOfTabs: number, setTabFocus: Function) => {
    let tabFocus = startingFocus;
    switch(e.keyCode){
        case KeyCode.Right:
            tabFocus += 1;
            // If we're past the end, move to the start
            if (tabFocus >= numberOfTabs) {
                tabFocus = 0;
            }
            break;
        case KeyCode.Left:
            tabFocus -= 1;
            // // If we're past the start, move to the end
            if (tabFocus < 0) {
                tabFocus = numberOfTabs - 1;
            }
            break;
        default:
            return;
    }
    const tabNeedingFocus = document.getElementById(`tab-${tabFocus}`);
    tabNeedingFocus?.focus();
    setTabFocus(tabFocus);
}

    
export function TallTabsRender(props: (IMapStateProps & TabsTallModel & IComponentProps)){

    let {header, description, tabs, id, windowWidth, htmlId} = props;
    // mobile can have multiple tabs open, so we use an array
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const [tabIndexFocus, setTabIndexFocus] = useState(0);
    const [pageDisplayType , setPageType] = useState(PageDisplayTypeEnum.Desktop);

    let filteredTabs = tabs
        ?.filter(tab=> tab &&
            [TallTabTypeEnum.SplitImage,TallTabTypeEnum.SplitLink]
                ?.includes(tab?.type || TallTabTypeEnum.Invalid
            ));

    useEffect(()=>{
        if(window.innerWidth > 991){
            setPageType(PageDisplayTypeEnum.Desktop);
            if(selectedIndices.length !== 1){
                // desktop must have only one index open
                setSelectedIndices([0]);
                setTabIndexFocus(0);
            }
        }else{
            setPageType(PageDisplayTypeEnum.Mobile);
        }
    },[selectedIndices, setSelectedIndices, pageDisplayType, setPageType, windowWidth, setTabIndexFocus, tabIndexFocus ]);

    return <div className="layout tall-tabs pb-0"  data-contentid={id} id={htmlId} onKeyDown={(e) => keyDownHandler(e, tabIndexFocus, filteredTabs?.length ?? 0, setTabIndexFocus)}
    >
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-8">
                    <div className="intro text-center">
                        <h2>{header}</h2>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="tabcordions">
            <div className="container tablist">
                <div className="row">
                    <div className="col-12">
                        <div role="tablist" aria-orientation="horizontal" aria-label="Tabs">
                            {filteredTabs?.map((tab, index)=>{
                                let isSelected = selectedIndices.includes(index);
                                return <TallTab key={index} {...{...tab, isSelected, index, onTabSelected:()=>{
                                    setSelectedIndices([index]);
                                    setTabIndexFocus(index);
                                }}}/>;
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-panels">
                {filteredTabs?.map( (tab, index) => {
                    let onTabSelected = () => {
                        setTabIndexFocus(index);
                        switch(pageDisplayType){
                            case PageDisplayTypeEnum.Mobile:
                                selectedIndices.includes(index) ? setSelectedIndices(selectedIndices.filter((e)=> e !== index)) : setSelectedIndices([...selectedIndices, index]);
                                break;
                            case PageDisplayTypeEnum.Desktop:
                                setSelectedIndices([index]);
                                break;
                            default:
                                return;
                        }
                    };
                    return <TabDetailPanel key={index} {...{...tab, onTabSelected, isSelected: selectedIndices.includes(index), index}} />
                })}
            </div>
        </div>
    </div>
}

TallTabsRender.displayName = "TallTabs";
export default connect (mapStateToProps) (withErrorBoundary(TallTabsRender));