import { useDispatch } from "react-redux";
import IComponentProps from "../../../interfaces/IComponentProps";
import LocationsPageModel from "../../../models/LocationsPageModel";
import Disclosures from "../../components/Disclosures/Disclosures";
import ImageList from "../../components/ImageList/ImageList";
import Locator from "../../components/Locator/Locator";
import { updateNavigationTheme } from "../../../redux/slices/pageContentSlice";

export default function LocationsPage(props: LocationsPageModel & IComponentProps) {
    let { contentBlock, imageList, htmlId, disclosures, locator } = props;
    const dispatch = useDispatch();
    dispatch(updateNavigationTheme('Dark'!))
    const className = "bg-gray padding-top-60";
    const { content } = imageList || {};

    return (
        <div id={htmlId}>
            <section>
                <Locator {...locator} />
            </section>
            <section>
                {<ImageList {...{content, contentBlock, className}}/>}
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}