import React, { useEffect, useRef, useState} from "react";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import MenuItem from "./MenuItem";
import NavMenuModel from "../../../../models/NavMenuModel";
import "./DesktopMenu.module.scss";
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";
import IThemedDisplay from "../../../../interfaces/IThemedDisplay";
import Logo from "../Logo/Logo";
import ScriptedDomIdsEnum from "../../../../enums/ScriptedDomIdsEnum";
import { KeyCode } from "../../../_shared/helpers/keyCode";
import { RootState } from "../../../../redux/store";
import { connect } from "react-redux";

interface IDesktopMenuRenderProps extends IThemedDisplay{
    navMenus?: NavMenuModel[] | null,
    activeIndex: number,//needs to be activeSubMenuIndex
    setActiveIndex: (index:number | null)=>void, //needs to be setActiveSubMenuIndex
    activeNavMenuIndex: number,
    isSticky: Boolean,
    lastScrollPosition: number,
    desktopMenuSetHeight: Function
}
const keyDownHandler = (e: any, startingFocus: number, numberOfTabs: number, setTabFocus: Function) => {
    let tabFocus = startingFocus;
    let shouldFocus = true;
    switch(e.keyCode){
        case KeyCode.Escape:
            shouldFocus =false
            break;
        case KeyCode.Right:
            tabFocus += 1;
            // If we're past the end, move to the start
            if (tabFocus >= numberOfTabs) {
                tabFocus = 0;
            }
            break;
        case KeyCode.Left:
            tabFocus -= 1;
            // // If we're past the start, move to the end
            if (tabFocus < 0) {
                tabFocus = numberOfTabs - 1;
            }
            break;
        default:
            return;
    }
    const tabNeedingFocus = document.getElementById(`menuItem-${tabFocus}`);
    const aLink = tabNeedingFocus?.children[0] as HTMLAnchorElement;
    if(shouldFocus){
        aLink.focus();
        setTabFocus(tabFocus);
    }else{
        aLink.blur();
        setTabFocus(-1);
    }
}

interface IMapStateProps extends IThemedDisplay {
    navigationTheme: string
}

export const mapStateToProps = (state: RootState, ownProps?: any): IMapStateProps => {
    const {navigationTheme} = state.pageContent || {};
    return {
        navigationTheme,
    } as IMapStateProps;
}

function DesktopMenuRender(props: IDesktopMenuRenderProps & IMapStateProps) {

    let {
        navMenus = [],
        activeNavMenuIndex,
        theme = ThemeTypeEnum.Light,
        navigationTheme,
        setActiveIndex,
        activeIndex,
        isSticky,
        lastScrollPosition,
        desktopMenuSetHeight
    } = props;

    let activeNavMenu = (navMenus??[])[activeNavMenuIndex];

    let logo = navigationTheme === ThemeTypeEnum.Light ? activeNavMenu?.logoSecondary : activeNavMenu?.logo;

    const menuItems = activeNavMenu?.content;

    const [timerId, setTimerId] = useState(null as (NodeJS.Timeout | null));

    let onMouseEnter:React.MouseEventHandler = (e:React.MouseEvent)=> {
        if(timerId !== null) {
            clearTimeout(timerId);
            setTimerId(null);
        }
    };
    let onMouseLeave:React.MouseEventHandler = (e:React.MouseEvent)=> {
        let currentTimerId = setTimeout(()=>{
            if(currentTimerId !== null) {
                setActiveIndex(null);
            }
        }, 300);
        setTimerId(currentTimerId);
    };
    let setIndex = (index: number | null) => {
        setActiveIndex(index)
        setTimerId(null);
    };
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        desktopMenuSetHeight(divRef.current?.clientHeight);
    }, [lastScrollPosition, desktopMenuSetHeight]);

    return (
        <div id={ScriptedDomIdsEnum.DesktopHeader} className={`${isSticky ? "isSticky" : ""}`} ref={divRef} onKeyDown={(e) => keyDownHandler(e, activeIndex || 0, menuItems?.length ?? 0, setActiveIndex)}>
            <div className="container">
                <div className="row align-items-center">
                    <Logo {...logo}/>
                    <div className="col-8 col-lg-9 col-xl-9 d-flex justify-content-end align-items-center">
                        <nav id="megamenu" {...{onMouseLeave, onMouseEnter}}>
                            <ul className="menu" role="menu">
                                {menuItems?.map((i, index)=>{
                                    return <MenuItem key={index} {...{index, indexCount:menuItems.length, setActiveIndex: setIndex, activeIndex, ...i}} />
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

DesktopMenuRender.displayName = "DesktopMenu";
export default connect (mapStateToProps) (withErrorBoundary(DesktopMenuRender));